function share_on_twitter(myUrl) {
    window.open('http://twitter.com/share?url=' + myUrl, '_blank').focus();
}

function share_on_facebook(myUrl) {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + myUrl, '_blank').focus();
}

$(document).ready(function() {
    $(".share-on-facebook").click(function() {
        var url = $(this).attr('data-shareUrl');
        share_on_facebook(url);
    });

    $(".share-on-twitter").click(function() {
        var url = $(this).attr('data-shareUrl');
        share_on_twitter(url);
    });

    $(".news-share-fb").click(function() {
        var url = $(this).attr('data-shareUrl');
        share_on_facebook(url);
    });

    $(".news-share-twitter").click(function() {
        var url = $(this).attr('data-shareUrl');
        share_on_twitter(url);
    });
});